import 'isomorphic-fetch';
import { Box, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { getSidebarLayout } from '@components/Layout';
import { BalanceList } from '@components/SpendableBalances';
import { fromAtomic } from '@utils/getDenomInfo';
import { SWAP_FEE } from 'src/constants';
import useAllStrategies from '@hooks/useAllStrategies';
import { Strategy } from '@models/Strategy';
import { Coin } from '@cosmjs/stargate';
import { getEndDateFromRemainingExecutions } from '@helpers/getEndDateFromRemainingExecutions';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryHistogram, VictoryTheme, VictoryTooltip } from 'victory';
import { StrategyType } from '@models/StrategyType';
import { formatFiat } from '@helpers/format/formatFiat';
import {
  getStrategyRemainingExecutions,
  getStrategyType,
  isStrategyActive,
  isStrategyAutoStaking,
} from '@helpers/strategy';
import { isDcaPlus } from '@helpers/strategy/isDcaPlus';
import { InitialDenomInfo } from '@utils/DenomInfo';
import { isNaN } from 'lodash';
import useBalances from '@hooks/useBalances';
import { useChainId } from '@hooks/useChainId';
import { getDCAContractAddress, getFeeTakerAddress } from '@helpers/chains';
import useFiatPrices from '@hooks/useFiatPrices';
import useDenoms from '@hooks/useDenoms';
import { values } from 'rambda';

function orderCoinList(denoms: { [x: string]: InitialDenomInfo }, coinList: Coin[], fiatPrices: any) {
  if (!coinList) {
    return [];
  }
  return coinList
    .map((coin) => {
      const { coingeckoId } = denoms[coin.denom];
      const denomConvertedAmount = fromAtomic(denoms[coin.denom], Number(coin.amount));
      const fiatPriceInfo = fiatPrices[coingeckoId];
      const fiatAmount = fiatPriceInfo ? denomConvertedAmount * fiatPrices[coingeckoId].usd : 0;
      if (isNaN(fiatAmount)) {
        return { ...coin, fiatAmount: 0 };
      }
      return { ...coin, fiatAmount };
    })
    .sort((a, b) => Number(b.fiatAmount) - Number(a.fiatAmount));
}

function getTotalSwappedForDenom(denom: InitialDenomInfo, strategies: Strategy[]) {
  return strategies
    .filter((strategy) => strategy.rawData.swapped_amount.denom === denom.id)
    .reduce((total, strategy) => total + Number(strategy.rawData.swapped_amount.amount), 0)
    .toFixed(6);
}

export function getTotalSwapped(strategies: Strategy[], supportedDenoms: InitialDenomInfo[]) {
  const totalSwapped = supportedDenoms.map(
    (denom) =>
      ({
        denom: denom.id,
        amount: getTotalSwappedForDenom(denom, strategies),
      } as Coin),
  );

  return totalSwapped;
}

function getTotalReceivedForDenom(denom: InitialDenomInfo, strategies: Strategy[]) {
  return strategies
    .filter((strategy) => strategy.rawData.received_amount.denom === denom.id)
    .reduce((total, strategy) => total + Number(strategy.rawData.received_amount.amount), 0)
    .toFixed(6);
}

function getTotalReceived(strategies: Strategy[], supportedDenoms: InitialDenomInfo[]) {
  const totalSwapped = supportedDenoms.map(
    (denom) =>
      ({
        denom: denom.id,
        amount: getTotalReceivedForDenom(denom, strategies),
      } as Coin),
  );

  return totalSwapped;
}

function getStrategiesByStatus(allStrategies: Strategy[], status: string) {
  const strategiesByStatus = allStrategies.filter((strategy) => strategy.status === status) || [];
  const percentage = (Number(strategiesByStatus.length / allStrategies.length) * 100).toFixed(2);
  return { strategiesByStatus, percentage };
}

function getStrategiesByTimeInterval(allStrategies: Strategy[], timeInterval: string) {
  const strategiesByTimeInterval =
    allStrategies.filter((strategy) => strategy.rawData.time_interval === timeInterval) || [];
  const percentage = (Number(strategiesByTimeInterval.length / allStrategies.length) * 100).toFixed(2);
  return { strategiesByTimeInterval, percentage };
}

function getStrategiesByType(allStrategies: Strategy[], type: StrategyType) {
  const strategiesByType = allStrategies.filter((strategy) => getStrategyType(strategy) === type) || [];
  const percentage = (Number(strategiesByType.length / allStrategies.length) * 100).toFixed(2);
  return { strategiesByType, percentage };
}

export function totalFromCoins(denoms: { [x: string]: InitialDenomInfo }, coins: Coin[] | undefined, fiatPrices: any) {
  return (
    coins
      ?.filter((coin) => coin.denom in denoms)
      .map((balance) => {
        const denomInfo = denoms[balance.denom];
        const denomConvertedAmount = fromAtomic(denomInfo, Number(balance.amount));
        const fiatPriceInfo = fiatPrices[denomInfo.coingeckoId];
        const fiatAmount = fiatPriceInfo ? denomConvertedAmount * fiatPrices[denomInfo.coingeckoId].usd : 0;

        if (isNaN(fiatAmount)) {
          return 0;
        }
        return fiatAmount;
      })
      .reduce((amount, total) => total + amount, 0) || 0
  );
}

function daysUntil(date: Date) {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
}

function weeksUntil(date: Date) {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24 * 7));
}

function monthsUntil(date: Date) {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 3600 * 24 * 30));
}

function hoursUntil(date: Date) {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 3600));
}

// map functions to time intervals
const timeIntervalMap: Record<any, (date: Date) => number> = {
  every_second: () => 1,
  every_minute: () => 1,
  half_hourly: () => 30,
  hourly: hoursUntil,
  half_daily: () => 12,
  daily: daysUntil,
  weekly: weeksUntil,
  fortnightly: () => 2,
  monthly: monthsUntil,
};

function getSwapCountForStrategyUntilDate(strategy: Strategy, date: Date) {
  if (typeof strategy.rawData.time_interval === 'string') {
    return Math.min(timeIntervalMap[strategy.rawData.time_interval](date), getStrategyRemainingExecutions(strategy));
  }
  return Math.min(strategy.rawData.time_interval.custom.seconds, getStrategyRemainingExecutions(strategy));
}

function getFeesPerSwapForStrategy(strategy: Strategy) {
  const fees = SWAP_FEE * Number(strategy.rawData.swap_amount);
  const convertedFees = fromAtomic(strategy.initialDenom, fees);

  return convertedFees;
}

function getFeesUntilDate(strategy: Strategy, date: Date) {
  const swapCount = getSwapCountForStrategyUntilDate(strategy, date);
  return getFeesPerSwapForStrategy(strategy) * swapCount;
}

function getFiatPriceFromList(fiatPrices: any, denom: InitialDenomInfo) {
  return fiatPrices[denom.coingeckoId]?.usd;
}

function getProjectedRevenueForStrategyForDate(strategy: Strategy, date: Date, fiatPrices: any) {
  const fees = getFeesUntilDate(strategy, date);
  const fiatPrice = getFiatPriceFromList(fiatPrices, strategy.initialDenom);
  return fees * fiatPrice;
}

function getProjectedRevenueForStrategiesForDate(strategies: Strategy[], date: Date, fiatPrices: any) {
  return strategies
    .map((strategy) => getProjectedRevenueForStrategyForDate(strategy, date, fiatPrices))
    .reduce((amount, total) => total + amount, 0);
}

function timeUntilEndOfStrategyInMilliseconds(strategy: Strategy) {
  const now = new Date();
  const now2 = new Date();
  const end = getEndDateFromRemainingExecutions(strategy, now2, getStrategyRemainingExecutions(strategy));
  if (!end) return 0;
  const diff = end.getTime() - now.getTime();
  return diff;
}

function getMaxDurationInMilliseconds(strategies: Strategy[]) {
  return Math.max(...strategies.map((strategy) => timeUntilEndOfStrategyInMilliseconds(strategy)));
}

function getAverageDurationForActiveStrategies(strategies: Strategy[]) {
  const totalDuration = strategies
    .filter(isStrategyActive)
    .map((strategy) => timeUntilEndOfStrategyInMilliseconds(strategy))
    .reduce((amount, total) => total + amount, 0);
  return totalDuration / strategies.length;
}

function groupStrategiesByOwnerThenStatus(strategies: Strategy[]): Record<string, Record<string, Strategy[]>> {
  const strategiesGroupedByOwnerThenStatus: Record<string, Record<string, Strategy[]>> = {};

  strategies.forEach((strategy: Strategy) => {
    const { owner, status } = strategy;

    if (!strategiesGroupedByOwnerThenStatus[owner]) {
      strategiesGroupedByOwnerThenStatus[owner] = {};
    }

    if (!strategiesGroupedByOwnerThenStatus[owner][status]) {
      strategiesGroupedByOwnerThenStatus[owner][status] = [];
    }

    strategiesGroupedByOwnerThenStatus[owner][status].push(strategy);
  });

  return strategiesGroupedByOwnerThenStatus;
}

function getWalletsWithOnlyScheduledStrategies(strategies: Strategy[]) {
  const strategiesByOwnerGroupedByStatus = groupStrategiesByOwnerThenStatus(strategies);
  return Object.keys(strategiesByOwnerGroupedByStatus).filter((owner) => {
    const statuses = Object.keys(strategiesByOwnerGroupedByStatus[owner]);
    return statuses.length === 1 && statuses[0] === 'scheduled';
  });
}

function getWalletsWithOnlyCancelledStrategies(strategies: Strategy[]) {
  const strategiesByOwnerGroupedByStatus = groupStrategiesByOwnerThenStatus(strategies);
  return Object.keys(strategiesByOwnerGroupedByStatus).filter((owner) => {
    const statuses = Object.keys(strategiesByOwnerGroupedByStatus[owner]);
    return statuses.length === 1 && statuses[0] === 'cancelled';
  });
}

function getWalletsWithActiveStrategies(strategies: Strategy[]) {
  const strategiesByOwnerGroupedByStatus = groupStrategiesByOwnerThenStatus(strategies);
  return Object.keys(strategiesByOwnerGroupedByStatus).filter((owner) => {
    const statuses = Object.keys(strategiesByOwnerGroupedByStatus[owner]);
    return statuses.includes('active');
  });
}

function getWalletsWithOnlyInactive(strategies: Strategy[]) {
  const strategiesByOwnerGroupedByStatus = groupStrategiesByOwnerThenStatus(strategies);
  return Object.keys(strategiesByOwnerGroupedByStatus).filter((owner) => {
    const statuses = Object.keys(strategiesByOwnerGroupedByStatus[owner]);
    return statuses.length === 1 && statuses[0] === 'inactive';
  });
}

function getWalletsWithOnlyInactiveAndCancelled(strategies: Strategy[]) {
  const strategiesByOwnerGroupedByStatus = groupStrategiesByOwnerThenStatus(strategies);
  return Object.keys(strategiesByOwnerGroupedByStatus).filter((owner) => {
    const statuses = Object.keys(strategiesByOwnerGroupedByStatus[owner]);
    return statuses.length === 2 && statuses.includes('inactive') && statuses.includes('cancelled');
  });
}

export function uniqueAddresses(allStrategies: Strategy[] | undefined) {
  return Array.from(new Set(allStrategies?.map((strategy) => strategy.owner) || []));
}

function Page() {
  const { denoms } = useDenoms();
  const { chainId } = useChainId();
  const { balances: contractBalances } = useBalances(getDCAContractAddress(chainId));
  const { balances: feeTakerBalances } = useBalances(getFeeTakerAddress(chainId));
  const { fiatPrices } = useFiatPrices();

  const { strategies } = useAllStrategies();

  const uniqueWalletAddresses = uniqueAddresses(strategies);

  if (!fiatPrices || !strategies || !denoms) return null;

  const totalInContract = totalFromCoins(denoms[chainId], contractBalances, fiatPrices);

  const totalInFeeTaker = totalFromCoins(denoms[chainId], feeTakerBalances, fiatPrices);

  const chainDenoms = values(denoms[chainId]);

  const totalSwappedAmounts = getTotalSwapped(strategies, chainDenoms);
  const totalSwappedTotal = totalFromCoins(denoms[chainId], totalSwappedAmounts, fiatPrices);

  const totalReceivedAmounts = getTotalReceived(strategies, chainDenoms);
  const totalReceivedTotal = totalFromCoins(denoms[chainId], totalReceivedAmounts, fiatPrices);

  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setDate(threeMonthsFromNow.getDate() + 90);

  const aYearFromNow = new Date();
  aYearFromNow.setDate(aYearFromNow.getDate() + 365);

  const thirtyDayRevenue = getProjectedRevenueForStrategiesForDate(strategies, thirtyDaysFromNow, fiatPrices);
  const threeMonthRevenue = getProjectedRevenueForStrategiesForDate(strategies, threeMonthsFromNow, fiatPrices);
  const twelveMonthRevenue = getProjectedRevenueForStrategiesForDate(strategies, aYearFromNow, fiatPrices);
  const averageDuration = getAverageDurationForActiveStrategies(strategies);
  const averageDurationInDays = Math.floor(averageDuration / (1000 * 60 * 60 * 24));

  const maxDurationInDays = getMaxDurationInMilliseconds(strategies) / (1000 * 60 * 60 * 24);

  return (
    <Stack spacing={6}>
      <Heading data-testid="details-heading">CALC statistics</Heading>
      <SimpleGrid spacing={12} columns={[1, null, 2, null, 3]}>
        <Stack spacing={2} layerStyle="panel" p={4}>
          <Heading size="md">Totals</Heading>
          <Heading size="sm" />
          <Text>Unique wallets with strategies: {uniqueWalletAddresses.length}</Text>
          <Text>Total strategies: {strategies?.length}</Text>
          <Text textStyle="body-xs">
            Strategies per wallet: {((strategies?.length || 0) / uniqueWalletAddresses.length).toFixed(2)}
          </Text>
          <Text textStyle="body-xs">
            Strategies with autostaking: {strategies?.filter(isStrategyAutoStaking).length}
          </Text>
          <Text textStyle="body-xs">Strategies using DCA+: {strategies?.filter(isDcaPlus).length}</Text>
          <Text textStyle="body-xs">
            Average Time Until End of Strategy: {averageDurationInDays} days (Max: {maxDurationInDays} days)
          </Text>
          <Text textStyle="body-xs">
            Unique wallets with only scheduled strategies: {getWalletsWithOnlyScheduledStrategies(strategies).length}
          </Text>
          <Text textStyle="body-xs">
            Unique wallets with active an active strategy: {getWalletsWithActiveStrategies(strategies).length}
          </Text>
          <Text textStyle="body-xs">
            Unique wallets with only completed strategies: {getWalletsWithOnlyInactive(strategies).length}
          </Text>
          <Text textStyle="body-xs">
            Unique wallets with only completed and cancelled strategies:{' '}
            {getWalletsWithOnlyInactiveAndCancelled(strategies).length}
          </Text>
          <Text textStyle="body-xs">
            Unique wallets with only cancelled strategies: {getWalletsWithOnlyCancelledStrategies(strategies).length}
          </Text>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Amount in contract</Heading>
          <Text>Total: {formatFiat(totalInContract)}</Text>
          <Box w={300}>
            <BalanceList balances={orderCoinList(denoms[chainId], contractBalances ?? [], fiatPrices)} />
          </Box>
        </Stack>
        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Amount in Fee Taker</Heading>
          <Text>Total: {formatFiat(totalInFeeTaker)}</Text>
          <Box w={300}>
            <BalanceList balances={orderCoinList(denoms[chainId], feeTakerBalances ?? [], fiatPrices)} />
          </Box>
        </Stack>
        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Projected revenue (30 Days)</Heading>
          <Text textStyle="body-xs">Based on expected executions and swap amounts </Text>
          <SimpleGrid columns={2} spacing={4} textStyle="bod">
            <Text> 30 Days: </Text>
            <Text>{formatFiat(thirtyDayRevenue)}</Text>
            <Text> 3 Months: </Text>
            <Text>{formatFiat(threeMonthRevenue)}</Text>
            <Text> 1 Year: </Text>
            <Text>{formatFiat(twelveMonthRevenue)}</Text>
          </SimpleGrid>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Amount Swapped</Heading>
          <Text>Total: {formatFiat(totalSwappedTotal)}</Text>
          <Box w={300}>
            <BalanceList balances={orderCoinList(denoms[chainId], totalSwappedAmounts, fiatPrices)} />
          </Box>
        </Stack>
        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Amount Received</Heading>
          <Text>Total: {formatFiat(totalReceivedTotal)}</Text>
          <Box w={300}>
            <BalanceList balances={orderCoinList(denoms[chainId], totalReceivedAmounts, fiatPrices)} />
          </Box>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Strategies By Status</Heading>
          <VictoryChart theme={VictoryTheme.material}>
            <VictoryAxis
              dependentAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryBar
              data={['scheduled', 'active', 'inactive', 'cancelled'].map((timeInterval: string) => {
                const { strategiesByStatus } = getStrategiesByStatus(strategies || [], timeInterval) || [];

                return {
                  x: timeInterval,
                  y: strategiesByStatus.length,
                  label: `${timeInterval} \n(${strategiesByStatus.length})`,
                };
              })}
              colorScale={['tomato', 'orange', 'gold', 'cyan']}
              style={{
                labels: {
                  fill: 'white',
                },
              }}
            />
          </VictoryChart>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Strategies By Status Unique</Heading>
          <VictoryChart theme={VictoryTheme.material}>
            <VictoryAxis
              dependentAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryBar
              data={['scheduled', 'active', 'inactive', 'cancelled'].map((status: string) => {
                const strategiesByStatus = Array.from(
                  new Set(
                    (getStrategiesByStatus(strategies || [], status) || []).strategiesByStatus.map(
                      (strategy) => strategy.owner,
                    ),
                  ),
                );
                return {
                  x: status,
                  y: strategiesByStatus.length,
                  label: `${status} \n(${strategiesByStatus.length})`,
                };
              })}
              colorScale={['tomato', 'orange', 'gold', 'cyan']}
              style={{
                labels: {
                  fill: 'white',
                },
              }}
            />
          </VictoryChart>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Strategies By Time Interval</Heading>
          <VictoryChart theme={VictoryTheme.material}>
            <VictoryAxis
              dependentAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryBar
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              data={['hourly', 'daily', 'weekly', 'monthly'].map((timeInterval: string) => {
                const { strategiesByTimeInterval, percentage } =
                  getStrategiesByTimeInterval(strategies || [], timeInterval) || [];

                return {
                  x: timeInterval,
                  y: strategiesByTimeInterval.length,
                  label: `${percentage}%`,
                };
              })}
              colorScale={['tomato', 'orange', 'gold', 'cyan']}
              style={{
                labels: {
                  fill: 'white',
                },
              }}
            />
          </VictoryChart>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Strategies By Type</Heading>
          <VictoryChart theme={VictoryTheme.material}>
            <VictoryAxis
              dependentAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryBar
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              data={[StrategyType.DCAIn, StrategyType.DCAOut, StrategyType.DCAPlusIn, StrategyType.DCAPlusOut].map(
                (type: StrategyType) => {
                  const { strategiesByType, percentage } = getStrategiesByType(strategies || [], type) || [];

                  return {
                    x: type,
                    y: strategiesByType.length,
                    label: `${percentage}%`,
                  };
                },
              )}
              colorScale={['tomato', 'orange', 'gold', 'cyan']}
              style={{
                labels: {
                  fill: 'white',
                },
              }}
            />
          </VictoryChart>
        </Stack>

        <Stack spacing={4} layerStyle="panel" p={4}>
          <Heading size="md">Active Strategies By Remaining Days</Heading>
          <VictoryChart theme={VictoryTheme.material}>
            <VictoryAxis
              dependentAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryAxis
              tickFormat={(tick) => `${tick}`}
              style={{
                grid: { stroke: '#F4F5F7', strokeWidth: 0.5 },
              }}
            />
            <VictoryHistogram
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              bins={20}
              data={strategies.filter(isStrategyActive).map((strategy: Strategy) => {
                const remainingTime = timeUntilEndOfStrategyInMilliseconds(strategy);
                const remainingTimeInDays = Math.round(remainingTime / (1000 * 60 * 60 * 24));
                return {
                  x: remainingTimeInDays,
                  label: remainingTimeInDays,
                };
              })}
              labelComponent={<VictoryTooltip />}
              colorScale={['tomato', 'orange', 'gold', 'cyan']}
              style={{
                labels: {
                  fill: 'white',
                },
              }}
            />
          </VictoryChart>
        </Stack>
      </SimpleGrid>
    </Stack>
  );
}

Page.getLayout = getSidebarLayout;

export default Page;
